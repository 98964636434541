import React, { useRef, useState } from "react";
import "./ContactSec.scss";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import Textarea from "../Textarea";
import Button from "../Button";
import { postUserInfo } from "../../apis/backend_helper";

const ContactSec = () => {
  const messageRef = useRef();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await postUserInfo(formValues);
      setIsLoading(false);
      if (res?.ok) {
        setFormValues({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      }
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };

  return (
    <section className="contactSec">
      <div className="container">
        <div className="contactSec_content">
          <h3>Get in touch</h3>
          <p>
            {/* Diam facilisi insolens per cu, eos malorum voluptaria
            concludaturque. */}
            <strong>VISIT THE MAKERSPACE!</strong>
          </p>

          <p>
            <small>ADDRESS</small>
            Creator Zone 9015 Vernon Rd, Bldg G, Lake Stevens, WA 98258{" "}
            <div>
              <a
                href="https://maps.app.goo.gl/FY7YyFJoTfUSC8z1A"
                rel="noreferrer"
                target="_blank"
              >
                View the map
              </a>
            </div>
          </p>
          <p>
            <small>MEMBER ONLY MAKERSPACE HOURS</small>
            <i>See schedule for classes & instructor hours</i>
            <span> Open Play: Tue - Fri: 5-7pm</span>
            <span>
              Sat & Sun: See schedule for upcoming classes & events!
            </span>
          </p>
          <p>
            <small>PUBLIC TOURS</small>
            <span>
              We are no longer accepting walk-ins, please pre-book a tour by
              emailing{" "}
              <a href="mailto:hello@creatorzone.com">hello@creatorzone.com</a> ,
              or see schedule to book a public class.
            </span>
          </p>
        </div>
        <form onSubmit={handleSubmit} className="contactSec_form">
          <div className="contactSec_formInputs">
            <TextInput
              value={formValues.firstName}
              onChange={(e) =>
                setFormValues({ ...formValues, firstName: e.target.value })
              }
              required
              label="First Name"
              placeholder="e.g. John"
            />
            <TextInput
              value={formValues.lastName}
              onChange={(e) =>
                setFormValues({ ...formValues, lastName: e.target.value })
              }
              required
              label="Last Name"
              placeholder="e.g. Dowry"
            />
            <TextInput
              value={formValues.email}
              onChange={(e) =>
                setFormValues({ ...formValues, email: e.target.value })
              }
              label="Email"
              type="email"
              placeholder="Add  your email address"
              required
            />
            <TextInput
              value={formValues.phone}
              onChange={(e) =>
                setFormValues({ ...formValues, phone: e.target.value })
              }
              label="Phone number"
              type="number"
              placeholder="+1 123 456 7890"
              required
            />
          </div>
          <Dropdown
            label="Subject"
            value={formValues.subject}
            onChange={(e) =>
              setFormValues({ ...formValues, subject: e.target.value })
            }
            options={[
              {
                label: "Select a reason for contacting us",
                value: "",
              },
              {
                label: "Becoming a member",
                value: "Becoming a member",
              },
              {
                label: "Donation",
                value: "Donation",
              },
              {
                label: "Equipment inquiry",
                value: "Equipment inquiry",
              },
              {
                label: "Other",
                value: "Other",
              },
            ]}
            required
          />
          <Textarea
            value={formValues.message}
            onChange={(e) =>
              setFormValues({ ...formValues, message: e.target.value })
            }
            ref={messageRef}
            label="Additional Message"
            placeholder="Type a message here..."
            maxLength={300}
          />
          <Button disabled={isLoading} type="submit">
            {isLoading ? "Sending..." : "Send your message"}
          </Button>
        </form>
      </div>
    </section>
  );
};

export default ContactSec;
