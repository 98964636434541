import React, { useEffect, useState } from "react";
import "./PartyModal.scss";
import Modal from "../Modal";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import DatePicker from "../DatePicker";
import Button from "../Button";
// import PartyModalCarousel from "../PartyModalCarousel";
import XMark from "../../assets/images/x-mark.svg";
import CongratsImage from "../../assets/images/congrats.svg";
import { postPartyInfo } from "../../apis/backend_helper";

const ThanksModal = ({ setIsOpen }) => {
  const handleContactUs = () => {
    setIsOpen(false);
    setTimeout(
      () =>
        document
          ?.getElementById("contact")
          .scrollIntoView({ behavior: "smooth" }),
      500
    );
  };
  return (
    <div className="party_thanksModal">
      <div className="party_thanksModalTop">
        <img onClick={() => setIsOpen(false)} src={XMark} alt="cross" />
      </div>
      <img
        src={CongratsImage}
        className="party_thanksModalCongrats"
        alt="congrats"
      />
      <h3>Thank you for filling out the form</h3>
      <p>
        Provide a brief explanation of the next steps in the process (e.g. you
        will receive an email or a representative will contact you etc)
      </p>
      <Button onClick={() => setIsOpen(false)}>Back to the website</Button>
      <p>
        If you have any issues{" "}
        <span onClick={handleContactUs}> contact us</span>{" "}
      </p>
    </div>
  );
};

const Form = ({ setIsComplete }) => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    numOfGuests: "",
    guestsAge: "",
    phone: "",
    email: "",
    eventDate: "",
  });
  const [decor, setDecor] = useState("");
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [decorError, setDecorError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!decor) {
      setDecorError(true);
      return;
    }
    setLoading(true);
    let finalData = {
      ...data,
      eventDate: data.eventDate.toString(),
    };
    if (decor) {
      finalData = { ...finalData, decor };
    }
    if (activities.length) {
      finalData = { ...finalData, activities };
    }
    try {
      const res = await postPartyInfo(finalData);
      if (res?.ok) {
        setData({
          firstName: "",
          lastName: "",
          numOfGuests: "",
          guestsAge: "",
          phone: "",
          email: "",
          eventDate: "",
        });
        setDecor("");
        setActivities([]);
        setIsComplete(true);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleActivityChange = (e) => {
    const value = e.target.value;
    if (activities.includes(value)) {
      setActivities((prev) => prev.filter((act) => act !== value));
      return;
    }
    if (activities.length) {
      setActivities((prev) => [prev[0], value]);
    } else {
      setActivities([e.target.value]);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="partyModal_form">
      <h4>Room Hire</h4>
      <p>
        Enjoy an unforgettable celebration with a 2 hour room hire starting at
        $300 for 10 party goers! We happily accommodate larger groups up to 24
        attendees with additional per-person costs. Please include the maximum
        number you're expecting in your inquiry for a personalized quote.{" "}
      </p>
      <h4>
        Decor <span className="error">*</span>
      </h4>
      <p>
        Our decor package is an additional $100, we will set up & pack down the
        party room in one of our captivating themes. Please select from one of
        the following:
      </p>
      <div className="partyModal_formDecors">
        {[
          "Underwater Sea",
          "Wild Safari",
          "Winter Wonderland",
          "Space Adventure",
          "No Decor",
          "City Superhero"
        ].map((d, i) => (
          <label key={i} name="decor">
            <input
              type="radio"
              value={d}
              onChange={(e) => {
                setDecor(e.target.value);
                if (e.target.value) {
                  setDecorError(false);
                }
              }}
              onClick={() => {
                if (decor === d) {
                  setDecor("");
                  setDecorError(true);
                }
              }}
              checked={decor === d}
            />
            {d}
          </label>
        ))}
        {decorError && (
          <span className="error">Please select one of the above option.</span>
        )}
      </div>
      <h4>Food & Cake</h4>
      <p>BYO food and cake!</p>

      <h4>Activities</h4>
      <small>
        <b>
          A host/hostess will be at the party guiding party goers through the
          activities!
        </b>
      </small>
      <p>
      Each party includes a unique "Welcome Craft", a group photo, provided in digital format, as well as the Lego table, Snapcircuts or Magnatile Castle.
      </p>

      <p>
      Select activities for your party goers! Please tick interests that apply for your personalized quote:
      </p>
      <div className="partyModal_formActivities">
        {[
          "3D Print",
          "Laser Cut",
          "VR Headsets (12+ age, no additional cost)",
          "Custom Printed T-Shirts, Tumblers or Magnets",
          "STEM Kit",
          "Podcast recording",
          "Sew a tote or pillow",
        ].map((act) => (
          <label>
            <input
              type="checkbox"
              value={act}
              checked={activities.includes(act)}
              onChange={handleActivityChange}
            />
            {act}
          </label>
        ))}
      </div>

      <p>Please fill out the party details below & submit</p>
      <div className="partyModal_formMain">
        <TextInput
          value={data.firstName}
          onChange={(e) =>
            setData((prev) => ({ ...prev, firstName: e.target.value }))
          }
          required
          label="First Name"
          placeholder="e.g. John"
        />
        <TextInput
          value={data.lastName}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lastName: e.target.value }))
          }
          required
          label="Last Name"
          placeholder="e.g. Dowry"
        />
        <Dropdown
          value={data.numOfGuests}
          onChange={(e) =>
            setData((prev) => ({ ...prev, numOfGuests: e.target.value }))
          }
          label="Number of Guests"
          options={[
            {
              label: "Select max. number",
              value: "",
            },
            {
              label: "1-10",
              value: "1-10",
            },
            {
              label: "10-15",
              value: "10-15",
            },
            {
              label: "15-20",
              value: "15-20",
            },
            {
              label: "20+",
              value: "20+",
            },
          ]}
          required
        />
        <TextInput
          value={data.guestsAge}
          onChange={(e) =>
            setData((prev) => ({ ...prev, guestsAge: e.target.value }))
          }
          required
          label="Guests age"
          placeholder="Enter a range"
        />
        <TextInput
          value={data.phone}
          onChange={(e) =>
            setData((prev) => ({ ...prev, phone: e.target.value }))
          }
          required
          label="Phone number"
          type="number"
          placeholder="+1 123 456 7890"
        />
        <TextInput
          value={data.email}
          onChange={(e) =>
            setData((prev) => ({ ...prev, email: e.target.value }))
          }
          required
          label="Email"
          type="email"
          placeholder="Add your email address"
        />

        <DatePicker
          selected={data.eventDate}
          onChange={(date) => setData((prev) => ({ ...prev, eventDate: date }))}
          label="Event date"
          placeholderText="e.g. mm/dd/yyyy"
          dateFormat="MM-dd-yyyy"
          required
        />
      </div>

      <Button disabled={loading} type="submit">
        {loading ? "Submitting..." : "Submit"}
      </Button>
      <p>
        The CZ Party Team is excited to hear from you & will be in contact
        within the week!
      </p>
    </form>
  );
};

const PartyModal = ({ isOpen, setIsOpen }) => {
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setIsComplete(false), 200);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {isComplete ? (
        <ThanksModal setIsOpen={setIsOpen} />
      ) : (
        <div className="partyModal">
          <div className="partyModal_top">
            <h3>Planning your Birthday Party</h3>
            <img onClick={() => setIsOpen(false)} src={XMark} alt="cross" />
          </div>
          <Form setIsComplete={setIsComplete} />
        </div>
      )}
    </Modal>
  );
};

export default PartyModal;
