import React from "react";
import Modal from "../Modal";
import XMark from "../../assets/images/x-mark.svg";
import "./PurchasePopup.scss";

const PurchasePopup = ({ isOpen, setIsOpen, isTour = false }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="purchasePopup">
        <img
          src={XMark}
          onClick={() => setIsOpen(false)}
          alt="x"
          className="purchasePopup_cross"
        />
        <div>
          Please contact{" "}
          <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>
          {isTour
            ? " to schedule a tour."
            : " for more details on memberships."}
        </div>
      </div>
    </Modal>
  );
};

export default PurchasePopup;
