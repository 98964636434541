import React, { useState } from "react";
import "./PartySec.scss";
import Button from "../Button";
import PartyModal from "../PartyModal";
import TextWhiteBg from "../../assets/images/party-text-white-bg.svg";
import TextWhiteBg2 from "../../assets/images/party-text-white-bg2.svg";
import TextGreyBg from "../../assets/images/party-text-grey-bg.svg";
import TextGreyBg2 from "../../assets/images/party-text-grey-bg2.svg";
import Arrow1 from "../../assets/images/party-arrow1.svg";
import Arrow2 from "../../assets/images/party-arrow2.svg";
import Arrow3 from "../../assets/images/party-arrow3.svg";
import Arrow4 from "../../assets/images/party-arrow4.svg";
import Image1 from "../../assets/images/party1-image.jpeg";
import Image2 from "../../assets/images/party2-image.jpeg";
import Image3 from "../../assets/images/party3-image.jpeg";
import Image4 from "../../assets/images/party4-image.jpeg";
import Image5 from "../../assets/images/party5-image.jpeg";
import Image6 from "../../assets/images/party6-image.jpeg";
import Image7 from "../../assets/images/party7-image.jpeg";
import Stars1 from "../../assets/images/party-stars1.svg";
import Stars2 from "../../assets/images/party-stars2.svg";
import Hearts1 from "../../assets/images/party-hearts1.svg";
import Circle1 from "../../assets/images/party-circle1.svg";
import Circle2 from "../../assets/images/party-circle2.svg";

const PartySec = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <PartyModal isOpen={modalOpen} setIsOpen={setModalOpen} />
      <section className="partySec" >
        <div id="party" className="partySec_idBox" />
        <img src={Circle1} alt="circle1" className="partySec_circle1" />
        <img src={Circle2} alt="circle2" className="partySec_circle2" />
        <div className="container">
          <div className="partySec_header">
            <div className="partySec_headerContent">
              <small>Party room</small>
              <h3>Birthday Parties at Creator Zone!</h3>
              <p>
                Celebrate your special day at Creator Zone’s Birthday Party
                room! Whether you're into arts & crafts, tech or a little bit of
                both we’ve got engaging group activities for your party.
                Customized to the party goers interest and skill levels, our
                parties offer hands-on excitement with take home creations. Let
                your creativity shine for a celebration that will be fondly
                remembered for years to come!
              </p>
              <div className="partySec_btns">
                <Button onClick={() => setModalOpen(true)}>Inquire</Button>
                {/* <Button white>Download PDF</Button> */}
              </div>
            </div>
            <div className="partySec_imageContainer1">
              <img
                src={Arrow1}
                alt="arrow"
                className="partySec_imageContainer1Arrow"
              />
              <img
                src={Image1}
                className="partySec_imageContainerPicture"
                alt="image1"
              />
              <div className="partySec_heading">
                <img
                  src={TextWhiteBg}
                  alt="white"
                  className="partySec_headingWhite"
                />
                <img
                  src={TextGreyBg}
                  alt="grey"
                  className="partySec_headingGrey"
                />
                <span>Group photo on the greenscreen</span>
              </div>
            </div>
          </div>
          <div className="partySec_main">
            <div className="partySec_imageContainer2">
              <img
                src={Arrow2}
                alt="arrow"
                className="partySec_imageContainer2Arrow"
              />
              <img
                src={Image2}
                className="partySec_imageContainerPicture"
                alt="image1"
              />
              <div className="partySec_heading">
                <img
                  src={TextWhiteBg}
                  alt="white"
                  className="partySec_headingWhite"
                />
                <img
                  src={TextGreyBg}
                  alt="grey"
                  className="partySec_headingGrey"
                />
                <span>AI generated backgrounds</span>
              </div>
            </div>
            <div className="partySec_imageContainer3">
              <img
                src={Hearts1}
                alt="arrow"
                className="partySec_imageContainer3Stars"
              />
              <img
                src={Image3}
                className="partySec_imageContainerPicture"
                alt="image1"
              />
              <div className="partySec_heading">
                <img
                  src={TextWhiteBg2}
                  alt="white"
                  className="partySec_headingWhite"
                />
                <img
                  src={TextGreyBg2}
                  alt="grey"
                  className="partySec_headingGrey"
                />
                <span>Crafting away!</span>
              </div>
            </div>
            <div className="partySec_imageContainer4">
              <img
                src={Stars1}
                alt="arrow"
                className="partySec_imageContainer4Stars"
              />
              <img
                src={Image4}
                className="partySec_imageContainerPicture"
                alt="image1"
              />
              <div className="partySec_heading">
                <img
                  src={TextWhiteBg2}
                  alt="white"
                  className="partySec_headingWhite"
                />
                <img
                  src={TextGreyBg2}
                  alt="grey"
                  className="partySec_headingGrey"
                />
                <span>Under The Sea Theme!</span>
              </div>
            </div>
            <div className="partySec_imageContainer5">
              <img
                src={Stars1}
                alt="arrow"
                className="partySec_imageContainer5Stars"
              />
              <img
                src={Arrow3}
                alt="arrow"
                className="partySec_imageContainer5Arrow"
              />
              <img
                src={Image5}
                className="partySec_imageContainerPicture"
                alt="image1"
              />
              <div className="partySec_heading">
                <img
                  src={TextWhiteBg}
                  alt="white"
                  className="partySec_headingWhite"
                />
                <img
                  src={TextGreyBg}
                  alt="grey"
                  className="partySec_headingGrey"
                />
                <span>Community craft event</span>
              </div>
            </div>
            <div className="partySec_imageContainer6">
              <img
                src={Arrow3}
                alt="arrow"
                className="partySec_imageContainer6Arrow"
              />
              <img
                src={Image6}
                className="partySec_imageContainerPicture"
                alt="image1"
              />
              <div className="partySec_heading">
                <img
                  src={TextWhiteBg2}
                  alt="white"
                  className="partySec_headingWhite"
                />
                <img
                  src={TextGreyBg2}
                  alt="grey"
                  className="partySec_headingGrey"
                />
                <span>Busy minds & hands!</span>
              </div>
            </div>
            <div className="partySec_imageContainer7">
              <img
                src={Stars2}
                alt="stars"
                className="partySec_imageContainer7Stars"
              />
              <img
                src={Arrow4}
                alt="arrow"
                className="partySec_imageContainer7Arrow"
              />
              <img
                src={Image7}
                className="partySec_imageContainerPicture"
                alt="image1"
              />
              <div className="partySec_heading">
                <img
                  src={TextWhiteBg2}
                  alt="white"
                  className="partySec_headingWhite"
                />
                <img
                  src={TextGreyBg2}
                  alt="grey"
                  className="partySec_headingGrey"
                />
                <span>Enjoying a custom craft project in the winter wonderland theme</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartySec;
